

@font-face {
    font-family: 'MyWebFont';
    src: url('./assets/sora-Regular.ttf') format('truetype');
    font-display: swap
  }

  @font-face {
    font-family: "MyWebFontBold";
    src: url('./assets/sora-Bold.ttf');
    font-display: swap
}

@font-face {
  font-family: "MyWebFontExtraBold";
  src: url('./assets/sora-ExtraBold.ttf');
  font-display: swap
}

:root {
  --appYellow-color: #ffde59;
  --black-color: black;

}

.mainContainerConfig
{
  min-height: 100vh; 
  display: flex;
  flex-direction: column;

}

.extraBold
{
  font-family: "MyWebFontExtraBold";
}




.yellow-snackbar{
  color: black !important;
  --mdc-snackbar-container-color: var(--appYellow-color) !important;
  --mdc-snackbar-supporting-text-color:var(--black-color) !important;

}

.blackBackground{
  background-color: black !important;
}




html, body {
 font-family: "MyWebFont" !important; 

}

body { 
  min-height: 100vh;
  display: flex;
  flex-direction: column;

 
  background-color: black !important;

//   background: url("./assets/redthaipepper.jpg");
// background-attachment:fixed;

// background-size: cover;
// -webkit-background-size: cover;
// -moz-background-size: cover;
// -o-background-size: cover;
// background-position: center center;
// background-repeat: no-repeat;

}

    



.bold900
{
  font-weight:900;
}

.socialMediaIcon{
  font-size: 30px;
  cursor: pointer;
}

.paddingRightSocialMediaIcon{
  padding-right: 15px;
}

.breakSpaces
{
  white-space:normal;
}

.whiteBackgroundColor
{
  background-color: white !important;
}

.whiteColor
{
  color: white !important;
}

.blackColor
{
  color:black
}


.clearBackground .mat-dialog-container{
  background-color: none !important;
  padding: 0;

}

.loadingSpinnerBackground .mat-dialog-container{
  background: transparent !important;
  padding: 0;

}

.subscribeDialog .mat-dialog-container {
  background-color: white !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  padding: 0;
}

.clearBackground
{
    background-color: transparent !important;

}


.title{
  font-family: "MyWebFontBold" !important;
} 

.appBlue
{
  color: #5ce1e6 !important;
}

.appYellow
{
  color: #ffde59 !important;
}

.whiteColor{
  color:white
}


.boxOneSetup
{
  background-color: transparent !important;
  color: white !important;
}

.greenColor
{
  color: green;
}

.appBlueBackground
{
  background-color: #5ce1e6 !important;
}

.appYellowBackground
{
  background-color: #ffde59 !important;
}

.itemTitle
{
  display: flex;
  justify-content: center;
  border-top-style: groove;
  border-bottom-style: groove;
  border-bottom-color: white;
  border-top-color: white;
  padding: 15px;
}



.greenBackGroundColor
{
    background-color: green !important;
}

.orangeBackGroundColor
{
  background-color: orange !important;
}



.cursorPointer
{
  cursor: pointer;
}


.rajdFont
{
    font-family:"MyWebFont" !important ;
}

.fontmd
{
  font-size: 18px;
}

  .example-is-mobile{
    overflow-y: auto;
  }

  .boxShadow
  {
    -webkit-box-shadow: 2px 4px 14px 9px rgba(0,0,0,0.47) !important; 
    box-shadow: 2px 4px 14px 9px rgba(0,0,0,0.47) !important;
  }

  .whiteBoxShadow{
    -webkit-box-shadow: 2px 2px 18px -3px #FFFFFF !important; 
box-shadow: 2px 2px 18px -3px #FFFFFF !important;
  }


  .spicyColor
  {
    color: #FF1A00;
  }

  .borderRadius25
  {
      border-radius: 25px !important;
  }

  .noOverFlow-x{
    overflow-x: hidden;
  }



